import React from "react";
import { ChatHistory, ChatHistoryItem } from "../system/atoms/ChatHistory";
import { SendMessage } from "../system/atoms/SendMessage";
import fetchStreamingInference from "../../utils/fetchInference";
import fetchModel from "../../utils/fetchModel";
import { PLAYGROUND_MODELS } from "./Playground";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { addCredits, UserInfo } from "../user/UserInfoPage";

interface InteractivePlaygroundProps {
  token: string;
  default_model: string | null; // If there is a default model set, there will be no option to change the model
  custom_name: string;
  shouldFetchModel: boolean;
  outputLength: number;
  systemPrompt: string;
  maxInferenceTime: number;
  showWarningTime?: number;
  chatHistory: Array<ChatHistoryItem>;
  setChatHistory: React.Dispatch<React.SetStateAction<ChatHistoryItem[]>>;
  userInfo: UserInfo;
}

export const get_start_message = (model_name: string) => {
  const model_list = PLAYGROUND_MODELS.map((value) => value.name);
  if (model_list.includes(model_name)) {
    return [
      {
        message: `Hi! I am ${model_name}. How can I help?`,
        entity: "model",
      },
    ];
  }
  return [
    { message: "Hi! I am your tuned model. How can I help?", entity: "model" },
  ];
};

export default function InteractivePlayground({
  token,
  default_model,
  custom_name,
  shouldFetchModel,
  outputLength,
  systemPrompt,
  maxInferenceTime,
  showWarningTime,
  chatHistory,
  setChatHistory,
  userInfo,
}: InteractivePlaygroundProps) {
  const [userTurn, setUserTurn] = React.useState<boolean>(true);
  const [message, setMessage] = React.useState("");
  const [isCreditModalOpen, setIsCreditModalOpen] = React.useState(false);
  React.useEffect(() => {
    default_model &&
      shouldFetchModel &&
      fetchModel(
        default_model,
        () => {},
        () => {},
        token
      );
  }, [default_model]);
  React.useEffect(() => {
    setChatHistory(get_start_message(custom_name));
  }, []);

  const addCreditsHandler = async (account_id: number) => {
    const res = await addCredits(token, account_id);
    window.open(res);
  };

  const sendMessage = () => {
    if (["error"].includes(chatHistory.slice(-1)[0].entity)) {
      chatHistory.pop();
      chatHistory.pop();
    }
    setUserTurn(false);
    setChatHistory([
      ...chatHistory,
      { entity: "user", message },
      { entity: "system", message: "pending" },
    ]);
    const setNextModelResponse = (response: string, responseType: string) => {
      if (["warning"].includes(responseType)) {
        setChatHistory([
          ...chatHistory,
          { entity: "user", message },
          { entity: "system", message: "pending" },
          {
            entity: responseType,
            message: response,
          },
        ]);
        return;
      }
      // responseType is "error" if there was an error
      setChatHistory([
        ...chatHistory,
        { entity: "user", message },
        {
          entity: responseType,
          message: response,
        },
      ]);
    };
    fetchStreamingInference(
      message,
      chatHistory,
      default_model,
      setNextModelResponse,
      token,
      outputLength,
      systemPrompt,
      maxInferenceTime,
      showWarningTime,
      setIsCreditModalOpen
    ).then(() => {
      setUserTurn(true);
    });
  };

  return (
    <>
      <div className="grow mb-2 min-h-0">
        <ChatHistory
          chatHistory={chatHistory}
          isGenerating={!userTurn}
        ></ChatHistory>
      </div>
      <SendMessage
        message={message}
        onSendMessage={sendMessage}
        setMessage={setMessage}
        userTurn={userTurn}
      ></SendMessage>
      <div>
        <p className="text-[12px] text-[#bababa] text-center mt-2">
          Models can make mistakes. Check important info.
        </p>
      </div>
      <Dialog
        open={isCreditModalOpen}
        onClose={() => setIsCreditModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center  p-4">
          <DialogPanel className="max-w-lg space-y-4 border bg-[#1a1a1a] border-[#393939] rounded-[16px] p-8">
            <DialogTitle className="font-bold">Out of Credits</DialogTitle>
            <Description>
              You've run out of credits. To continue using Lamini, please buy
              more credits.
            </Description>
            <div className="flex gap-4">
              <button onClick={() => setIsCreditModalOpen(false)}>
                Cancel
              </button>
              <button
                className="max-w-lg space-y-4 border bg-[#FF6C71]  border-[#393939] rounded-lg p-2"
                onClick={() => {
                  addCreditsHandler(userInfo.account_id);
                  setIsCreditModalOpen(false);
                }}
              >
                Buy Credits
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
