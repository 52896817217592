import React from "react";
import { FullScreen } from "../system/layouts/FullScreen";
import InteractivePlayground, {
  get_start_message,
} from "./InteractivePlayground";
import PlaygroundSettings from "./PlaygroundSettings";
import {
  XMarkIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/20/solid";
import { Popover } from "@headlessui/react";
import ModelSelector, { ModelInfo } from "./ModelSelector";
import { ChatHistoryItem } from "../system/atoms/ChatHistory";
import { UserInfo } from "../user/UserInfoPage";

interface PlaygroundProps {
  token: string;
  userInfo: UserInfo;
}

const mistralSystemPrompt =
  "Always assist with care, respect, and truth. Respond with utmost utility yet securely. Avoid harmful, unethical, prejudiced, or negative content. Ensure replies promote fairness and positivity.";
const phiSystemPrompt = "";
const llamaSystemPrompt =
  "You are a helpful, respectful and honest assistant. Always answer as helpfully as possible, while being safe. Your answers should not include any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Please ensure that your responses are socially unbiased and positive in nature.\n\nIf a question does not make any sense, or is not factually coherent, explain why instead of answering something not correct. If you don’t know the answer to a question, please don’t share false information.";

// Please add models to the inference_request_check healthcheck in the backend if you add them here
export const PLAYGROUND_MODELS: ModelInfo[] = [
  {
    name: "Llama 3.1",
    value: "meta-llama/Meta-Llama-3.1-8B-Instruct",
    systemPrompt: llamaSystemPrompt,
    maxLength: 4000,
    headerStart: "<|begin_of_text|><|start_header_id|>user<|end_header_id|>",
    headerEnd: "<|eot_id|><|start_header_id|>assistant<|end_header_id|>",
    outputEnd: "<|eot_id|>",
  },
  {
    name: "Mistral 3",
    value: "mistralai/Mistral-7B-Instruct-v0.3",
    systemPrompt: mistralSystemPrompt,
    maxLength: 4000,
    headerStart: "[INST] ",
    headerEnd: " [/INST]",
    outputEnd: "",
  },
  {
    name: "Llama 3.2-3B-Instruct",
    value: "meta-llama/Llama-3.2-3B-Instruct",
    systemPrompt: llamaSystemPrompt,
    maxLength: 4000,
    headerStart: "<|begin_of_text|><|start_header_id|>user<|end_header_id|>",
    headerEnd: "<|eot_id|><|start_header_id|>assistant<|end_header_id|>",
    outputEnd: "<|eot_id|>",
  },
  {
    name: "Tiny Random Mistral",
    value: "hf-internal-testing/tiny-random-MistralForCausalLM",
    systemPrompt: phiSystemPrompt,
    maxLength: 480,
    headerStart: "",
    headerEnd: "",
    outputEnd: "",
  },
];

/*
 * Primary UI component for user interaction
 */
export const Playground = (props: PlaygroundProps) => {
  const [selectedModel, setSelectedModel] = React.useState(
    PLAYGROUND_MODELS[0]
  );
  const [outputLength, setOutputLength] = React.useState(
    Math.floor(selectedModel.maxLength / 2)
  ); // Default value for output length

  React.useEffect(() => {
    setOutputLength(Math.floor(selectedModel.maxLength / 2));
  }, [selectedModel]);

  const [chatHistory, setChatHistory] = React.useState<Array<ChatHistoryItem>>(
    get_start_message(selectedModel.name)
  );
  const onModelSelect = (model: ModelInfo) => {
    setSelectedModel(model);
    setChatHistory(get_start_message(model.name));
  };

  return (
    <FullScreen>
      <div className="flex flex-col md:grid md:grid-cols-[auto_300px] md:gap-4 p-2 pt-14 md:p-6 md:pt-16 w-full h-full">
        <div className="md:hidden flex mb-2">
          <div className="flex-grow">
            <ModelSelector
              models={PLAYGROUND_MODELS}
              selectedModel={selectedModel}
              setSelectedModel={onModelSelect}
            />
          </div>
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button className="px-3 py-2 w-full justify-between text-sm font-semibold text-white">
                  {open ? (
                    <XMarkIcon
                      className="h-7 w-7 text-white"
                      aria-hidden="true"
                    />
                  ) : (
                    <AdjustmentsHorizontalIcon
                      className="h-7 w-7 text-white"
                      aria-hidden="true"
                    />
                  )}
                </Popover.Button>

                <Popover.Panel className="absolute h-[90vh] right-[-8px] p-[16px] z-10 mt-2 w-[100vw] max-w-[900px] origin-top-right rounded-md bg-[#1a1a1a] ring-1 ring-white ring-opacity-5 focus:outline-none">
                  <PlaygroundSettings
                    models={PLAYGROUND_MODELS}
                    selectedModel={selectedModel}
                    setSelectedModel={onModelSelect}
                    outputLength={outputLength}
                    setOutputLength={setOutputLength}
                  />
                </Popover.Panel>
              </>
            )}
          </Popover>
        </div>
        <div className="grow flex flex-col h-full overflow-hidden">
          <InteractivePlayground
            token={props.token}
            default_model={selectedModel.value}
            custom_name={selectedModel.name}
            outputLength={outputLength}
            systemPrompt={selectedModel.systemPrompt}
            shouldFetchModel={false}
            maxInferenceTime={13}
            showWarningTime={3}
            chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            userInfo={props.userInfo}
          />
        </div>
        <div className="hidden md:block relative">
          <PlaygroundSettings
            models={PLAYGROUND_MODELS}
            selectedModel={selectedModel}
            setSelectedModel={onModelSelect}
            outputLength={outputLength}
            setOutputLength={setOutputLength}
          />
        </div>
      </div>
    </FullScreen>
  );
};
