import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { GlobalDataContext, GlobalDataContextType } from "./GlobalDataContext";

type Props = {
  children: React.ReactNode;
};
export default function GlobalDataProvider({ children }: Props) {
  const [globalData, setGlobalData] = React.useState<GlobalDataContextType>({
    config: {},
    loading: true,
  });

  React.useEffect(() => {
    async function fetchInitialData() {
      try {
        const response = await fetch(
          (process.env.REACT_APP_API_URL || "") + "/v1/version",
          { method: "GET" }
        );
        const resJson = await response.json();
        setGlobalData({ config: resJson, loading: false });

        Sentry.init({
          dsn: resJson["sentry"],
          environment: resJson["environment"],
          defaultIntegrations: false,
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          tracesSampleRate: 1.0,
        });
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    }

    fetchInitialData().then();
  }, []);
  return (
    <GlobalDataContext.Provider value={globalData}>
      {children}
    </GlobalDataContext.Provider>
  );
}
